import { ArrowRightAlt, Cancel, CheckBoxOutlineBlank, CheckCircle, CheckCircleOutlineRounded, CheckCircleRounded, CheckRounded, Delete, ExpandMore, KeyboardArrowDownRounded, KeyboardDoubleArrowDownRounded } from '@mui/icons-material';
import { Autocomplete, Accordion, AccordionSummary, AccordionDetails, TextField, Typography, FormControl, Select, MenuItem, Checkbox, Icon, IconButton, Tooltip } from '@mui/material'
import React, { useContext, useMemo, useState } from 'react'
import { useEffect } from 'react';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import DateFnsUtils from "@date-io/date-fns";
import { filterContext } from './Filters';
import './AttributeList.css';
// import DateRangePickerComponent from '../grid_src/components/DateRangePickerComponent';
const options = []
// const relation = ["in"]

 function AttributeList(props) {
  const { elementIndex, attributeId, attribute } = props;
  const {tenantMapperData,icp,tenantId, relationObj, onClearAttributes,onAddFilterItem,deleteFilter,screen,startdate,enddate } = useContext(filterContext);
  const [relation,setRelation] = useState([]);
  const [rerender,SetRender] = useState(0);
  const [attributeValues, setAttributeValues] = useState([]);
  const [fromDate, setFromDate] = React.useState((attribute.values[1])?attribute.values[1]:new Date(Date.now() - 86400000))
  const [toDate, setToDate] = React.useState((attribute.values[1])?attribute.values[1]:new Date())
  const fetchResultChange = async() => {
    let route = ``;
    if(screen=='display') route = `/analytics/displayads/campaignName/`
    else if(screen === 'google_ads'){
      if(attribute.attribute === 'Campaign Name') route = `/googleads/parentData/campaignList/`;
      else if(attribute.attribute === 'Status') route = `/googleads/parentData/statusList/`
      else if(attribute.attribute === 'Type') route = `/googleads/parentData/typeList/`
    }else if(screen === 'google_ads_group'){
      if(attribute.attribute === 'Status') route = `/googleads/groupData/statusList/`;
      else if(attribute.attribute === 'Type') route = `/googleads/groupData/typeList/`;
    }else if(screen === 'google_ads_adskeywords'){
      if(attribute.attribute === 'Ad Status') route = `/googleads/adsGroupData/statusList/`;
      else if(attribute.attribute === 'Ad Type') route = `/googleads/adsGroupData/typeList/`;
      else if(attribute.attribute === 'Ad Strength') route = `/googleads/adsGroupData/strengthList/`;
      else if(attribute.attribute === 'Match Type') route = `/googleads/keywordsGroupData/matchtypeList/`;
      else if(attribute.attribute === 'Status') route = `/googleads/keywordsGroupData/statusList/`;
    }else{
      route = `/analytics/forfilters/parentprograms/`;
    }
    if(route){
      fetch(window._env_.REACT_APP_UNIFIED_URL + route+tenantId +`?startdate=${startdate}&enddate=${enddate}&icp=${icp}`, {
      method:"POST",
      credentials: 'include' })
      .then((result) => result.json())
      .then((json) => {
          let campaignTypes = [];
          campaignTypes = json["data"].map((obj) => {
            let pushObj={
              programName: obj.parentprogramname??obj.campaignName??obj.status??obj.type,
              source: obj.source,
            };
            return pushObj;
          });
          if (campaignTypes?.length > 0) {
              setAttributeValues(campaignTypes);
              console.log("programName==>",campaignTypes);
          } else {
          }
      })
    }
}
  useEffect(()=>{
    fetchResultChange()
    // relationCheck()
  },[attribute.attribute])
  useEffect(()=>{
    relationCheck()
  },[attribute.type])

  console.log('tenantMapperData',tenantMapperData)
  const [inputText,setInputText] = useState();  
  
  
  
  const OnAttributeChange=(value,type,index=0)=>{ 
   
      if (type=='attribute'){
        attribute.attribute = value
        attribute.relation = ''
        attribute.values = []
      }
      if(type=='relation'){
        attribute.relation = value
        if(attribute.type === 'number'){
          attribute.values = [0,1]
        }
        else if(attribute.type === 'date'){
          attribute.values = [new Date(),new Date(Date.now()-86000)]
        }
        // attribute.values = []
        SetRender(rerender+1);
      }
        if(type == 'values'){
          if(attribute.type === 'number' || attribute.type === 'date' || attribute.type === 'dropdown'){
            attribute.values[index] = value;
          }
          else{
            attribute.values = value; 
          }
          // if(attribute.values.length==0){
          //   SetRender(rerender+1);
          // }
        }
        }
        
      
    const checkIsValid = ()=>{
      
      if(attribute.attribute && attribute.relation  &&( attribute.values.length>0 || (attribute.relation == 'is empty'|| attribute.relation == 'is not empty' || attribute.type === 'dropdown'))) {
        attribute.isValid = true
        return false
      }
      return true
    }
    const ValueComponent = (params)=> {
      const [incr,setIncr] = useState(0)
    
      if(attribute.relation == 'is empty' || attribute.relation == 'is not empty')  return(<></>)
      else if (attribute.type == "list"){
        return (
          <Autocomplete
            size="small"
            disableClearable
            limitTags={5}
            disableCloseOnSelect
            popupIcon={
              <KeyboardArrowDownRounded style={{ color: "#21DA8C" }} />
            }
            onChange={(event, newValue) => {
              params.OnAttributeChange(newValue, "values");
              setIncr((inc) => inc + 1);
            }}
            value={attribute?.values}
            id="controllable-states-demo"
            options={attributeValues}
            renderTags={(value, getTagProps) => (
              <>
                <Typography
                  variant="body1"
                  style={{
                    fontSize: "16px",
                    color: "rgba(3, 3, 3, 0.6)",
                    width: "8vw",
                  }}
                  width={"8vw"}
                  noWrap
                >
                  {value
                    .slice(0, 1)
                    .map((e) => e.programName)
                    .join()}{" "}
                </Typography>
                {value.length - 1 > 0 && <>+{value.length - 1}</>}
              </>
            )}
            getOptionLabel={(option) => option.programName}
            getOptionSelected={(option, value) =>
              option.programName === value.programName
            }
            style={{ marginBottom: 0, minWidth: "15.5vw", maxWidth: "18vw" }}
            renderOption={(props, option, { selected }) => {
              console.log('selectbox0', props)
              //           const selectAllProps = option === "Select All" // To control the state of 'Select All' checkbox
              // ? { checked: selectAllValues } : {};
              return (
                <li {...props}>
                  <Checkbox
                    icon={<CheckBoxOutlineBlank fontSize="small" />}
                    checkedIcon={<CheckBoxIcon fontSize="small" />}
                    style={{ marginRight: 8 }}
                    checked={selected}
                    // {...selectAllProps}
                  />
                  <img
                    width="20"
                    height="20"
                    src={`${window._env_.REACT_APP_INTENTSETTING}/logos/${
                      option.source == null ? `` : option.source.toLowerCase()
                    }.png`}
                  />

                  {option.programName}
                </li>
              );
            }}
            // filterOptions={(options, params) => {
            //       const filtered = filters(options, params);
            //       return ["Select All", ...filtered];
            //     }}
            multiple
            renderInput={(params) => (
              <TextField
                error={false}
                placeholder="value"
                {...params}
                variant="outlined"
                onInput={handleInput}
              />
            )}
          />
        );
      }
      else if (attribute.type == "number")
        return (
          <div style={{ display: "flex", gap: "1vw" }}>
            <TextField
              id="outlined-number"
              placeholder="value"
              type="number"
              onFocus
              size="small"
              style={{
                minWidth: attribute.relation == "between" ? "7vw" : "15.5vw",
              }}
              onChange={(event, newValue) => {
                params.OnAttributeChange([event.target.value], "values", 0);
                setIncr((icr) => icr + 1);
              }}
              value={attribute.values[0]}
              variant="outlined"
              InputLabelProps={{
                shrink: true,
              }}
            />
            {attribute.relation == "between" && (
              <TextField
                id="outlined-number"
                placeholder="value"
                type="number"
                onFocus
                size="small"
                style={{ minWidth: "7vw" }}
                onChange={(event, newValue) => {
                  params.OnAttributeChange([event.target.value], "values", 1);
                  setIncr((icr) => icr + 1);
                }}
                value={attribute.values[1]}
                variant="outlined"
                InputLabelProps={{
                  shrink: true,
                }}
              />
            )}
          </div>
        );
      else if (attribute.type == "date")
        return (
          <div style={{ display: "flex", gap: "1vw" }}>
            <TextField
              placeholder="value"
              style={{
                minWidth: attribute.relation == "between" ? "7vw" : "15.5vw",
              }}
              onFocus
              id="date"
              format="dd/MM/yyyy"
              size="small"
              type="date"
              value={attribute.values[0]}
              onChange={(event, newValue) => {
                params.OnAttributeChange([event.target.value], "values", 0);
                setIncr((icr) => icr + 1);
              }}
            />
            {attribute.relation == "between" && (
              <TextField
                style={{ minWidth: "7vw" }}
                placeholder="value"
                id="date"
                size="small"
                type="date"
                value={attribute.values[1]}
                onChange={(event, newValue) =>
                  params.OnAttributeChange([event.target.value], "values", 1)
                }
              />
            )}
          </div>
        );
      else if(attribute.type === "dropdown")
        return (
          <Autocomplete
            size="small"
            popupIcon={
              <KeyboardArrowDownRounded style={{ color: "#21DA8C" }} />
            }
            onChange={(event, newValue) => {
              params.OnAttributeChange(newValue?.programName, "values");
              setIncr((inc) => inc + 1);
            }}
            value={attribute?.values[0]}
            id="controllable-states-demo2"
            options={attributeValues}
            getOptionLabel={(option) => {
              if(Array.isArray(option) && option.length > 0) return option[0].programName;
              else if(typeof(option) === 'object') return option.programName;
              else return option;
            }}
            getOptionSelected={(option, value) => option[0].programName === value[0].programName}
            style={{ marginBottom: 0, minWidth: "15.5vw", maxWidth: "18vw" }}
            renderInput={(params) => (
              <TextField
                error={false}
                placeholder="value"
                {...params}
                variant="outlined"
                onInput={handleInput}
              />
            )}
          />
        );
      // else if(attribute.type == 'date' && attribute.relation=="between" ) return(
      //   // <DateRangePickerComponent toDate={toDate} setToDate={setToDate} fromDate={fromDate} setFromDate={setFromDate} style={{ minWidth: "15.5vw"  }} attributeData={attribute} onAttributeChange={params.OnAttributeChange} />
      //   )
      else
        return (
          <TextField
            style={{ minWidth: "15.5vw" }}
            placeholder="value"
            variant="outlined"
            size="small"
            value={attribute.values[0]}
            onChange={(event, newValue) =>
              params.OnAttributeChange([event.target.value], "values")
            }
          />
        );
      
    }
    const relationCheck =()=>{
      // attribute.type = tenantObj['type']
      try {
        const val = relationObj[attribute.type]['operators']
        setRelation(val);
      } catch (error) {
        console.log(error);
      }

    }
    
  const onClickCheck = (value,arr) => {
    console.log("onClickCheck",value,arr);

    OnAttributeChange(arr, "attribute");
    const tenantObj = tenantMapperData.find(e=>e.label===arr);
    console.log("tenantObj",tenantObj);
          attribute.type = tenantObj['type']
          setRelation(relationObj[attribute.type]['operators']);
    // relationCheck()
  };
    const handleInput=(event)=>{
      // event.preventDefault;
      console.log("preventing default");
    }

  return (
    <div>
        
    <div className='attributegrp'>    
<Autocomplete
      id="grouped-demo"
      size='small'
     options={(tenantMapperData).filter(e=>e.filter && e.label).map(e=>e.label)}
      // groupBy={(option) => option.groupName}
      // getOptionLabel={(option) => option.label}
      // sx={{ width: 150 }}
      style={{minWidth:"15.5vw"}}
      value={attribute.attribute}
      onChange={(value,arr)=>onClickCheck(value,arr)}
      popupIcon={<KeyboardArrowDownRounded style={{color:"#21DA8C"}}/>}
      renderInput={(params) => (
        <TextField  {...params} style={{fontSize:"14px", fontStyle:"italic",minWidth:"15.5vw" }} placeholder="Select Attributes" variant="outlined" />
      )}
     
/>  

            <FormControl
              size="small"
              variant="outlined"
            >
              {/* <InputLabel id="demo-simple-select-label">Relation</InputLabel> */}
              <Select
                size="small"
                placeholder='Relation'
                options={relation}
                displayEmpty
                defaultValue="Relation"
                value={attribute.relation}
                onClick={()=>relationCheck()}
                onChange={(event)=>{
                  OnAttributeChange(event.target.value,'relation');
                }}
                style={{ width: "10vw" }}
                // error={shouldShowErrors("relation")}
                IconComponent={KeyboardArrowDownRounded}
                renderValue={(value) => value === "Relation" ? <label style={{ color: "#A0A0A0", fontStyle: "italic", }}>{value}</label> : <label>{value}</label>}
                // onOpen={(event) => {
                //   if (attributeData?.attribute)
                //     fetchRelationType(attributeData?.attribute);
                // }}
              >
                {relation.map((data) => (
                  <MenuItem
                    value={data}
                    onClick={() => {
                    //   if (data === "includes") {
                    //     fetchAttributeValues();
                    //   }
                    //   OnAttributeChange(data, "relation");
                    }}
                    style={{ textOverflow: "ellipsis", color: "#A0A0A0",padding:"6px 16px",justifyContent:"left",float:"left" }}
                  >
                    {data}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <div style={{width:'15.5vw'}}>
            <ValueComponent OnAttributeChange={OnAttributeChange}/>
            </div>
            
            <div style={{ width: "5.3vw" }}>
          {elementIndex === 0 ? (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent:"space-between"
              }}
            >
              <Tooltip disableFocusListener title="Clear">
                <IconButton aria-label="clear" >
                  <Cancel
                    style={{
                      color: "#FF8585",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      onClearAttributes();
                      // setTypedValue('');
                    }}
                  />
                </IconButton>
              </Tooltip>

              <Tooltip disableFocusListener title="Save">
                <IconButton aria-label="Save"  >
                  <CheckCircle
                    style={{
                      cursor: "pointer",
                      color:  "#21DA8C",
                    }}
                    onClick={() => {
                      checkIsValid();
                      onAddFilterItem()
                      //   validateData(attributeData),
                      //   elementIndex
                      // );
                    }}
                  />
                </IconButton>
              </Tooltip>
            </div>
          ) : (
            <div
              style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent:"space-between"
              }}
            >
              <Tooltip disableFocusListener title="Delete">
                <IconButton aria-label="delete">
                  <Delete
                    onClick={(event) => {
                      deleteFilter(elementIndex);
                    }}
                    style={{
                      color: "#FF8585",
                      // marginRight: "5px",
                      cursor: "pointer",
                      padding: 0,
                    }}
                  />
                </IconButton>
              </Tooltip>
            </div>
)}
        </div>
            {/* <TextField size='small'/> */}
            </div>
</div>
  )}

  export default AttributeList;